@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

.active-link {
    @apply text-base font-montserrat font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500;
}
.inactive-link {
    @apply text-base font-montserrat font-bold text-slate-800 dark:text-white;
}
.social-link {
    @apply w-5 fill-slate-400 cursor-pointer transition duration-500;
}
.tech-stack-list {
    @apply w-10 transition duration-500 dark:fill-slate-400;
}
